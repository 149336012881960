import Qs from 'qs'
import axios from 'axios'
import { ID_TOKEN_KEY } from '@/store/auth'

const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'https://cloud.certbase.com',
  baseURL: 'https://admin-api.sitestage.top',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    tgSecretKey: 'kkzKkn8jj9dRbyRSitsM0D85YKdp5xpKCQ64HE1irG8ZZCdMeQSDJsFwFQ8p0Xit'
  }
})
service.interceptors.request.use(
  config => {
    const api = localStorage.getItem('api')
    if (api) {
      config.baseURL = api
    }
    const token = localStorage.getItem(ID_TOKEN_KEY)
    if (!token) {
      //
    }
    config.headers.token = token
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      config.data = Qs.stringify(config.data)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const token = response.headers.authorization
    if (token) {
      localStorage.setItem(ID_TOKEN_KEY, token)
    }
    const res = response.data
    return res
  },
  error => {
    // if (error.status === 400) {
    //   return Promise.reject(error)
    // }
    // TOKEN 失效
    if (error.response && error.response.status === 401) {
      // localStorage.removeItem(ID_TOKEN_KEY)
      // return Promise.reject(error)
    }
    return error.response && error.response.data
  }
)
export default service

export class PublicAPI {
  get(url, params, option) {
    return service.get(url, { params, ...option }, option)
  }

  getOne(url, id, option) {
    return service.get(`${url}${id ? '/' + id : ''}`, option)
  }

  // post 请求
  post(url, params, option) {
    // delete params.pwd
    return service.post(url, params, option)
  }

  // put 请求
  put(url, id, params, option) {
    return service.put(`${url}${id ? '/' + id : ''}`, params, option)
  }

  // del 请求
  del(url, id, option) {
    return service.delete(`${url}${id ? '/' + id : ''}`, option)
  }

  // upd 请求
  upd(url, id, params, option) {
    return service.post(`${url}${id ? '/' + id : ''}`, params, option)
  }
}
