import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import GlobalComponents from './components/install'
import { formatAmount, formatDate } from '@/utils/format'
import i18n from '@/plugins/i18n'
import './test/index.spec'
import 'vant/lib/index.css'
import './style/index.less'
import './style/tailwind.css'

function bootstrap() {
  const store = createPinia()
  createApp(App)
    .use(store)
    .use(router)
    .use(Vant)
    .use(GlobalComponents)
    .use(i18n)
    .mixin({
      methods: {
        formatDate,
        formatAmount
      }
    })
    .mount('#app')
}
bootstrap()
