<template>
  <van-config-provider :theme="theme">
    <router-view v-if="isLogin" v-slot="{ Component }">
      <keep-alive :include="['Certificates']">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <div v-if="isShowTabbar" class="van-tabbar-container">
      <van-tabbar v-model="active">
        <van-tabbar-item
          v-for="item in menus"
          :key="item.path"
          :to="item.path"
          :name="item.path"
          :icon="item.icon"
          replace
        >
          {{ item.menuName }}
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </van-config-provider>
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue'
import { menus } from '@/router/menu'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useUserStore } from '@/store/user'

export default defineComponent({
  name: 'App',
  setup() {
    const authStore = useAuthStore()
    const userStore = useUserStore()
    const route = useRoute()
    const active = ref(route.path)
    const theme = ref('dark') // light | dark
    const isShowTabbar = computed(() => {
      const { meta } = route
      return meta.activeMenu
    })
    const isLogin = computed(() => authStore.isLogin)

    const init = () => {
      authStore.login()
    }
    init()

    watch(
      () => route.path,
      () => {
        if (route.meta.activeMenu === 'mine') {
          active.value = '/mine'
          return
        }
        active.value = route.path
      }
    )

    watch(
      () => isLogin.value,
      (v) => {
        if (!v) return
        userStore.fetchUser()
      },
      { immediate: true }
    )
    return {
      active,
      theme,
      menus,
      isLogin,
      isShowTabbar
    }
  }
})
</script>
<style>
.van-tabbar-container {
  width: 100%;
  height: var(--van-tabbar-height);
}
.van-tabbar-item__icon {
  padding: 3px 18px;
  transition: .2s ease;
}
.van-tabbar-item__text {
  transition: .2s ease;
}
.van-tabbar-item--active .van-tabbar-item__icon {
  background-color: rgba(67, 120, 255, .10);
  border-radius: 100px;
}
</style>
