// import CryptoJS from 'crypto-js'

import HmacSHA256 from 'crypto-js/hmac-sha256'
import Hex from 'crypto-js/enc-hex'

// const ORIGIN_HASH = '47b0aba02d68bd0ce28c6cdf6b14473b06ad42270bac3cb42577561e3651a994'
const initData = 'user=%7B%22id%22%3A6610339250%2C%22first_name%22%3A%22Min%22%2C%22last_name%22%3A%22Young%22%2C%22username%22%3A%22ymin996%22%2C%22language_code%22%3A%22zh-hans%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-8657580854389984098&chat_type=private&auth_date=1718351199&hash=47b0aba02d68bd0ce28c6cdf6b14473b06ad42270bac3cb42577561e3651a994'
const botToken = '6889975523:AAGF5j2rbOgGso53_pUMBHlBzxTOIWu3qlo'

const verifyTelegramWebAppData = (telegramInitData) => {
  const initData = new URLSearchParams(telegramInitData)
  const hash = initData.get('hash')
  const dataToCheck = []

  initData.sort()
  initData.forEach((val, key) => key !== 'hash' && dataToCheck.push(`${key}=${val}`))

  // const secret = crypto
  //   .createHmac('sha256', 'WebAppData')
  //   .update(TELEGRAM_BOT_TOKEN)
  //   .digest()
  const secret = HmacSHA256(botToken, 'WebAppData')

  // const _hash = crypto
  //   .createHmac('sha256', secret)
  //   .update(dataToCheck.join('\n'))
  //   .digest('hex')
  const _hash = HmacSHA256(dataToCheck.join('\n'), secret).toString(Hex)

  return hash === _hash
}

const valid = verifyTelegramWebAppData(initData)
console.log('valid', valid)
