import dayjs from 'dayjs'
export class OrderFormat {
  static formatProductName(order) {
    if (order.productName) {
      order.productName = order.productName.replace(RegExp(`^${order.brandName}`), '')
    }
    return `${order.brandName} ${order.productName}`
  }

  static formatStatus(order) {
    const { orderStatus, certStatus, certExpireDate } = order
    let tempStatus = 'draft'
    // 提交失败的情况
    if (/1003|1006/.test(orderStatus)) {
      tempStatus = 'draft'
      return {
        code: tempStatus,
        theme: 'warning',
        text: '重新提交'
      }
    }
    if (/1001/.test(orderStatus)) {
      tempStatus = 'draft'
      return {
        code: tempStatus,
        theme: 'danger',
        text: '等待支付'
      }
    }
    if (/1002/.test(orderStatus)) {
      tempStatus = 'toBeVerified'
      if (/3004/.test(certStatus)) {
        tempStatus = 'issued'
        const certExpireDateObj = dayjs(certExpireDate)
        const isNearExpire = certExpireDateObj
          .subtract(30, 'day')
          .isBefore(new Date())
        if (isNearExpire) {
          tempStatus = 'expiring'
        }
        // 已到期
        if (certExpireDateObj.isBefore(new Date())) {
          tempStatus = 'expired'
        }
      }
    }
    if (/1004/.test(orderStatus) && /3005/.test(certStatus)) {
      tempStatus = 'cancelled'
    }
    return {
      code: tempStatus,
      theme:
      {
        draft: '',
        issued: 'primary',
        expiring: 'warning',
        toBeVerified: 'warning',
        nearExpire: 'warning',
        cancelled: 'warning',
        expired: 'danger'
      }[tempStatus] || 'primary',
      text:
      {
        draft: /1003|1006/.test(orderStatus + '') ? '重新提交' : '等待支付',
        issued: '已经颁发',
        expiring: '即将过期',
        toBeVerified: '等待验证',
        nearExpire: '即将过期',
        cancelled: '已经取消',
        expired: '已经到期'
      }[tempStatus] || ''
    }
  }
}

// 日期格式化
export const formatDate = (date, format = 'YYYY-MM-DD') => {
  return dayjs(date).format(format)
}

// 日期格式化
export const parseTime = (date, format = 'YYYY-MM-DD') => {
  return dayjs(date).format(format)
}

// 金额格式化
export const formatAmount = (amount) => {
  return (Number(amount) / 100).toFixed(2)
}
