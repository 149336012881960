import { defineStore } from 'pinia'
import request from '@/utils/request'
import { genSpark } from '@/utils/ssl.key.util'

export const useUserStore = defineStore('user', {
  state: () => ({
    stateUser: {}
  }),
  getters: {
    user: state => state.stateUser
  },
  actions: {
    fetchUser() {
      return request.get('/v1/account')
        .then(result => {
          if (!result) return
          if (result.code === 200) {
            this.stateUser = { ...result.data, ...result.data.accountInfo }
            const { spark } = genSpark(this.stateUser.rootUUID)
            if (spark) {
              localStorage.setItem('spark', spark)
            }
          }
          return result
        })
    }
  }
})
