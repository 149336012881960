import request, { PublicAPI } from '@/utils/request'

// 获取订单列表
export const fetchOrderList = (params) => {
  return request.get('/v1/orders', { params })
}

// 查询订单列表，多个订单编号
export const fetchOrdersByOrderNosAction = (orderNos) => {
  return request.post('/v1/orders/batch', { orderNos }, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 计算价格
export const computedOrderPriceAction = (productNo, params) => {
  return request.post(`/v1/orders/price/${productNo}`, params, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 创建key（私钥和公钥）
export const createKeyAction = (params = {}) => {
  return request.post('/v1/csr/save', params)
}

// 付费证书创建订单(批量和非批量二合一)
export const createOrderAction = (productNo, params, isFree = false) => {
  if (isFree) {
    params.productNo = productNo
    return this.createFree(params)
  }
  return request.post(`/v1/orders/certificate/${productNo}`, params, {
    timeout: 1000 * 60 * 20, // 20分钟后取消请求
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export const fetchReIssueCert = (orderNo) => {
  return request.get(`/v1/orders/cert/${orderNo}`)
}

// 批量取消订单
export function batchCancelOrdersAction(orderNos = []) {
  return request.post('/v1/orders/cancel', { orderNos }, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 查询订单详情 orderNo -> 订单编号
export const fetchOrderDetails = (orderNo) => {
  return request.get(`/v1/orders/detail/${orderNo}`)
}

// 查询交易记录
export const fetchFinanceLog = (limit, offset, options = {}) => {
  const parmas = { ...options }
  for (const key in parmas) {
    // eslint-disable-next-line no-prototype-builtins
    if (parmas.hasOwnProperty(key) && !parmas[key]) {
      delete parmas[key]
    }
  }
  delete parmas.period
  return request.get('/v1/transactions', {
    params: {
      limit, offset, ...parmas
    }
  })
}

export default class OrderAPI extends PublicAPI {
  /**
   * 查询订单列表
   * @param limit
   * @param offset
   * @param status 订单状态
   * @returns {*|Promise|Promise<any>}
   */
  fetchOrderList(params) {
    return this.get('/v1/orders', params)
  }

  // 查询订单详情 orderNo -> 订单编号
  fetchOrderDetails(orderNo) {
    return this.get(`/v1/orders/detail/${orderNo}`)
  }

  // 查询订单状态
  fetchOrderStatus(orderNo) {
    return this.get(`/v1/orders/status/${orderNo}`, {}, {
      timeout: 1000 * 60
    })
  }

  // 订单统计
  ordersStatusCount() {
    return this.get('/v1/orders/count')
  }

  // 计算价格
  computedPrice(productNo, params) {
    return this.post(`/v1/orders/price/${productNo}`, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  // 重签计算价格
  computedResignPrice(orderNo, commonName, domains) {
    return this.post(`/v1/orders/reSignPrice/${orderNo}`, { domains })
  }

  // 创建key（私钥和公钥）
  createKey(params = {}) {
    return this.post('/v1/csr/save', params)
  }

  // 批量创建csr和key
  batchCreateKeies(domains, encryption, signature, keySize, keyCurve, orgUnit,
    country = 'SG', province = 'Beijing', locality = 'Beijing', organization = 'SSL') {
    const params = {
      hashSign: signature,
      encryption,
      keyCurve,
      keySize,
      country,
      province,
      locality,
      organization,
      orgUnit,
      domains
    }
    const options = {
      timeout: 1000 * 60 * 20,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    return this.post('/v1/csr/batchSave', params, options)
  }

  // 付费证书创建订单(批量和非批量二合一)
  create(productNo, params, isFree = false) {
    if (isFree) {
      params.productNo = productNo
      return this.createFree(params)
    }
    return this.post(`/v1/orders/certificate/${productNo}`, params, {
      timeout: 1000 * 60 * 20, // 20分钟后取消请求
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  // 创建免费证书
  createFree(params) {
    return this.post('/v1/orders/free', params)
  }

  // 重新提交订单
  reSubmitOrder(orderNo) {
    return this.post(`/v1/orders/reCommit/${orderNo}`, { orderNo })
  }

  // 获取订单详情中的域名列表
  fetchDomainListInOrder(orderNumber) { // limit, offset = 1 全表查询
    return this.get(`/v1/orders/domainList/${orderNumber}`, { limit: 5000 })
  }

  // 删除域名列表中的域名
  deleteDomain(orderNo, domainName) {
    return this.post('/v1/orders/domainList', { orderNo, domainName })
  }

  // 获取digicert域名详情
  fetchDomainDetails(orderNo, domain) {
    // return this.get(`/v1/orders/domainInfo/${orderNo}/${domain}`)
    return this.post('/v1/orders/dcv', { orderNo, domain })
  }

  // 查询已签证书详情
  fetchReIssueCert(orderNumber) {
    return this.get(`/v1/orders/cert/${orderNumber}`)
  }

  // 删除（取消）订单（未支付的在数据库中标识，已支付的发送邮件通知）
  cancelOrder(orderNo) {
    return this.post('/v1/orders/cancel', { orderNos: [orderNo] }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  // 续费证书
  renewCert(id, year) {
    return this.post('/createorderrenew', {
      orderid: id,
      year
    })
  }

  // 下载pfx证书
  downloadPfx(orderNo, key) {
    return this.post('/v1/cert/pfx', { orderNo, key })
  }

  // 下载jks
  downliadJks(orderNo, key) {
    return this.post('/v1/cert/jks', { orderNo, key })
  }

  /**
   * 重新发送验证邮件
   * @param {*} orderNo 订单编号
   */
  resendVerifyEmail(orderNo) {
    return this.put(`/v1/orders/reSendDcvEmail/${orderNo}`)
  }

  // 发送验证域名请求
  verifyDomainsRequest(orderNo) {
    return this.put(`/v1/orders/verifyDomains/${orderNo}`, '', {}, {
      timeout: 1000 * 60 * 20
    })
  }

  /**
   * 取消订单中的域名 | IP（多域名 | IP 证书可用）
   * 用户已支付就是取消域名，没有支付就是删除域名
   * @param {*} domain 域名或者IP
   * @param {*} certId CAID
   */
  cancelDomainInOrder(domain/* domain | IP */, certId) {
    return this.post('/cancelhost', { host: domain, certid: certId })
  }

  // 域名验证，自动更新DCV（多域名）
  verifyDomains(data, orderNo) {
    return this.put('/v1/orders/dcv', '', data, {
      headers: {
        'Content-Type': 'application/json' // 会跨域 附加 -> ;charset=UTF-8
      }
    })
  }

  // 重新签发
  reIssue(orderNo, csr, key, domainNames, dcvMethod) {
    domainNames = domainNames.replace(/,$/, '')
    return this.post('/v1/orders/reSign', { orderNo, csr, key, domainNames, dcvMethod })
  }

  // 检测证书
  checkCertInstall(commonName) {
    return this.get('/v1/autoDetectDomain', { commonName })
  }

  // 获取价格表
  fetchPriceTables(levelName) {
    // return this.get('/v1/myPrice', { levelName })
  }
}
