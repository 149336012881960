<template>
  <van-popover
    v-model:show="showPopover"
    placement="bottom-start"
    :show-arrow="false"
    @select="onSelect"
    v-bind="$attrs"
  >
    <template #reference>
      <div class="g-select pl-2 pr-4 w-full relative">
        <input
          :value="inputValue"
          :placeholder="placeholder"
          readonly
          class="outline-none p-0 border-none bg-transparent"
        />
        <div class="absolute right-0 top-0 g-select-icon flex justify-center items-center" @click="onClear">
          <van-icon v-if="inputValue" name="clear" />
          <van-icon v-else name="arrow" class="g-select-arrow" />
        </div>
      </div>
    </template>
  </van-popover>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useToggle } from '@vant/use'

export default defineComponent({
  name: 'GSelect',
  props: {
    placeholder: {
      type: String,
      default: '请选择'
    }
  },
  setup() {
    const [showPopover] = useToggle(false)
    const inputValue = ref('')
    const onSelect = (v) => {
      inputValue.value = v.text
    }

    const onClear = () => {
      if (!inputValue.value) return
      inputValue.value = ''
      showPopover.value = false
    }
    return {
      showPopover,
      onSelect,
      onClear,
      inputValue
    }
  }
})
</script>

<style lang="less" scoped>
.g-select {
  background: var(--van-search-content-background);
  border-radius: var(--van-radius-sm);
  color: var(--van-field-input-text-color);
  input {
    height: var(--van-search-input-height);
    font-size: 14px;
    &::placeholder {
      color: var(--van-field-placeholder-text-color)
    }
  }
  .g-select-icon {
    height: var(--van-search-input-height);
    width: calc(var(--van-padding-md) * 2);
  }
  .g-select-arrow {
    transition: .3s ease;
  }
  &:focus-within .g-select-arrow {
    transform: rotate(90deg);
  }
}
</style>
