<template>
  <!--
    批量下单域名文本框
    1. 使用,分隔域名，使用;分隔订单
    2. 取每个订单的第一个域名做通用名称，生成csr，若第一个为ip或者通配符就取下一个，以此类推，取到一个符合生成csr的域名
    3. 组装数据
    4. 使用v-model响应
    5. 格式校验
    6. 错误提示使用红框，并且说明不规范域名
  -->
  <div class="batch-textarea" :class="{ danger: !!tipsMessage }">
    <van-cell-group inset class="p-0 m-0">
      <van-field
        v-model="domains"
        rows="1"
        :autosize="{ maxHeight: 150 }"
        :placeholder="placeholder"
        label=""
        type="textarea"
      />
    </van-cell-group>
    <div v-if="tipsMessage" class="tips animated fadeIn mt-1 text-gray">
      域名
      <span style="color: #f56c6c">{{ tipsMessage }}</span>
      格式不正确
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { delay } from '@/utils'
import {
  VerifyIp,
  VerifyDomain,
  VerifyWildcard,
  VerifySanCommonNames
} from '@/utils/validate/domain'
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: 'DomainTextarea',
  emits: ['domains-change', 'validated'],
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const domains = ref('')
    const tipsMessage = ref('')
    const isInitInput = ref(false) // 判断是否没有输入

    const placeholder = ref(setPlaceholder(props.product))
    // 订单规范检测
    const checkOrder = (val) => {
      const data = []
      const arr = val.split('\n\n')
      const orders = arr.filter((item) => {
        return Boolean(item.trim())
      })
      for (let i = 0; i < orders.length; i++) {
        const domainNames = orders[i].split('\n').filter((item) => !!item)
        if (!domainNames[0]) {
          continue
        }
        data.push({
          commonName: domainNames.shift(),
          domainNames
        })
      }
      tipsMessage.value = ''
      emit('domains-change', data)
      return data
    }
    // 清空域名框中的内容
    const handleTextareaFocus = () => {
      if (domains.value === setPlaceholder(props.product)) {
        isInitInput.value = true
        domains.value = ''
      }
    }

    const handleTextareaBlur = () => {
      if (!domains.value) {
        domains.value = setPlaceholder(props.product)
      }
    }

    watch(
      () => [domains.value, props.product],
      ([val, cert]) => {
        // 扩展类型 可单域名、flex、通配符
        if (!val) {
          tipsMessage.value = ''
          emit('domains-change', [])
          placeholder.value = setPlaceholder(cert)
          return
        }
        if (placeholder.value === val) {
          return
        }
        // 限制 500个 批量订单提交
        const orders = val
          .trim()
          .split('\n\n')
          .filter((item) => !!item)
        if (orders.length > 500) {
          delay(() => {
            ElNotification({
              type: 'warning',
              title: '警告',
              message: '一次最多可提交500个订单'
            })
          }, 500)
          return
        }
        const isFlex = /flex/.test(cert.productType)
        const isSingle = /one/.test(cert.productType)
        const isWild = /wildcard/.test(cert.productType)
        const isIp = /ip/.test(cert.productType)
        const isVmc = /vmc/.test(cert.productType)
        const isEv = /ev/.test(cert.productType) // EV 不支持通配
        tipsMessage.value = ''
        let verifyResult = false
        if (isIp) {
          const checkVal = val.replace(/\n\n/g, '\n')
          const { flag, host } = VerifyIp(checkVal, '\n', true)
          verifyResult = flag
          if (!verifyResult) {
            tipsMessage.value = host
            return false
          }
        }
        if (isFlex) {
          const checkVal = val.replace(/\n\n/g, '\n')
          verifyResult = VerifySanCommonNames(
            checkVal,
            props.product.subBrand,
            '\n',
            (err) => {
              tipsMessage.value = err
              if (isEv && /\*/.test(checkVal)) {
                tipsMessage.value += '，EV 证书不支持通配'
              }
            },
            { supportWild: !isEv }
          )
        }
        if (isVmc) {
          const checkVal = val.replace(/\n\n/g, '\n')
          verifyResult = VerifySanCommonNames(
            checkVal,
            props.product.subBrand,
            '\n',
            (err) => {
              // eslint-disable-line
              tipsMessage.value = err
              const { flag } = VerifyIp(checkVal, '\n')
              if (/\*/.test(checkVal) || !flag) {
                tipsMessage.value += '，VMC 证书不支持通配和 IP'
              }
            },
            { supportWild: false, supportIp: false }
          )
        }
        if (isSingle) {
          const checkVal = val.split('\n\n').filter((item) => !!item)
          for (const i in checkVal) {
            const item = checkVal[i]
            if (/\n/g.test(item)) {
              tipsMessage.value = '此产品不支持多域名'
              verifyResult = false
              break
            }
            verifyResult = VerifyDomain(item)
            if (!verifyResult) {
              tipsMessage.value = item
              break
            }
          }
        }
        if (isWild) {
          const checkVal = val.split('\n\n')
          // eslint-disable-next-line
          checkVal.map((item) => {
            if (!item) return item
            if (/\n/.test(item)) {
              tipsMessage.value = '此产品不支持多域名'
              verifyResult = false
              return false
            }
            verifyResult = VerifyWildcard(item)
            if (!verifyResult) {
              tipsMessage.value = `${item} 不是通配符域名`
              return false
            }
          })
        }
        emit('validated', verifyResult)
        try {
          verifyResult && checkOrder(val)
        } catch (e) {
          tipsMessage.value = e.message
        }
      },
      { deep: true, immediate: true }
    )
    return {
      domains,
      isInitInput,
      tipsMessage,
      placeholder,
      handleTextareaFocus,
      handleTextareaBlur
    }
  }
})

// 提示信息处理
function setPlaceholder({ productType }) {
  const isFlex = /flex/.test(productType)
  const isSingle = /one/.test(productType)
  const isWild = /wildcard/.test(productType)
  const isIp = /ip/.test(productType)
  const isVmc = /vmc/.test(productType)
  if (isSingle || isVmc) {
    return '请输入域名。分单换两行。例如：\nexample1.com\n\nexample2.com'
  }
  if (isFlex) {
    return '请输入域名。分单换两行。例如：\nwww.example1.com\n\n*.example.com\n\napp.example3.com\nwww.example3.com'
    // return '请输入域名。分单换两行。例如：&#10;www.example1.com&#10;&#10;*.example.com&#10;&#10;app.example3.com&#10;www.example3.com'
  }
  if (isWild) {
    return '请输入域名。分单换两行。例如：\n*.example1.com\n\n*.example2.com'
  }
  if (isIp) {
    return '请填写IP地址，分单换两行。例如：\n114.114.114.114\n\n114.114.114.113\n\n114.114.114.112\n114.114.114.111'
  }
}
</script>
