import psl from 'psl'
import RegMaven from './reg-exp-maven'

const isValid = psl.isValid
psl.isValid = function(domain) {
  return isValid(domain) && !/\.$/.test(domain) // fix: 修复以.结尾的域名也会通过
}

// 验证ipv4和ipv6
export const VerifyIp = (ips, symbol = ',', errHost = false) => {
  if (!ips) return false
  // 验证一个
  function validSingle(ip) {
    return (RegMaven.regMask4.test(ip) && !isLAN(ip)) || RegMaven.regMask6.test(ip)
  }
  const ipsArr = (ips.trim()).split(symbol)
  // if (ipsArr.length === 1) return validSingle(ipsArr[0])
  let flag = true
  let errHostItem = ''
  for (let i = 0; i < ipsArr.length; i++) {
    const factor = validSingle(ipsArr[i])
    if (!factor) {
      flag = false
      errHostItem = ipsArr[i]
      break
    }
  }
  if (errHost) {
    return { flag, host: errHostItem }
  }
  return flag
}

// 验证通配符
export const VerifyWildcard = (domain) => {
  if (!domain) return false
  // domain = domain.replace(/[\s]/, '')
  domain = domain.trim()
  const wildDomain = domain.substring(2)
  return /^\*\./.test(domain) && psl.isValid(wildDomain)
}

// 验证单域名
export const VerifyDomain = (domain) => {
  if (!domain) return false
  // domain = domain.replace(/[\s]/, '')
  domain = domain.trim()
  return psl.isValid(domain)
}

// 域名验证（单域名、多域名统一验证）
export const VerifyDomains = (domains, symbol = ',', callback) => {
  if (!domains) return false
  if (/;/.test(domains)) {
    domains = domains.replace(/[\s]|(;$)/g, '')
    domains = domains.replace(/;/g, symbol)
  }
  let flag = false
  const domainArr = domains.trim().split(symbol)
  let errDomains = ''
  for (let i = 0; i < domainArr.length; i++) {
    const d = domainArr[i]
    flag = psl.isValid(d) || VerifyWildcard(d)
    if (!flag) {
      errDomains = d
      flag = false
      break
    }
  }
  callback && callback(errDomains)
  return flag
}

// 验证多域名和IP
export const VerifySanCommonNames = (commonNames, brand, symbol = ',', callback, options = {}) => {
  // const { supportIp, supportWild } = options
  if (!commonNames || !brand) return false
  if (/;/.test(commonNames)) {
    commonNames = commonNames.replace(/[\s]|(;$)/g, '')
    commonNames = commonNames.replace(/;/g, symbol)
  }
  let flag = false
  const domainArr = commonNames.trim().split(symbol).filter(item => !!item)
  for (let i = 0; i < domainArr.length; i++) {
    const d = domainArr[i]
    flag = psl.isValid(d) || VerifyWildcard(d) || VerifyIp(d)
    if (options.onlyIp) {
      flag = VerifyIp(d) && !isLAN(d)
    }
    if (!flag) {
      callback && callback(d)
      flag = false
      break
    }
  }
  return flag
}

// 验证是否是内网ip
/* eslint-disable */
export function isLAN(ip) {
  ip.toLowerCase()
  if(ip=='localhost') return true
  var aIP = 0
  if(ip == '') return false
  var aNum = ip.split('.')
  if(aNum.length != 4) return false
  aIP += parseInt(aNum[0]) << 24
  aIP += parseInt(aNum[1]) << 16
  aIP += parseInt(aNum[2]) << 8
  aIP += parseInt(aNum[3]) << 0
  aIP = aIP >> 16 & 0xFFFF
  return(aIP >> 8 == 0x7F || aIP >> 8 == 0xA || aIP== 0xC0A8 || (aIP >= 0xAC10 && aIP <= 0xAC1F))
}
/* eslint-disable */