export const menus = [
  {
    menuName: '首页',
    icon: 'home-o',
    path: '/'
  },
  {
    menuName: '购买证书',
    icon: 'shield-o',
    path: '/certificates'
  },
  {
    menuName: '我的',
    icon: 'contact-o',
    path: '/mine'
  }
]
