import { defineStore } from 'pinia'
import request from '@/utils/request'
export const ID_TOKEN_KEY = 'id_token'
export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem(ID_TOKEN_KEY)
  }),
  getters: {
    isLogin: state => {
      return !!state.token
    }
  },
  actions: {
    // 自动登录
    login() {
      const params = {
        tgUserID: (window.tgUser && window.tgUser.id) || 6610339250
      }
      return request.post('/tg/login', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(() => {
          this.token = localStorage.getItem(ID_TOKEN_KEY)
        })
    }
  }
})
