<template>
  <van-action-sheet
    v-model:show="show"
    title="支付订单"
    @cancel="onActionSheetCancel"
  >
    <div class="p-4 text-sm">
      <PayOrderList :orders="payOrders" />
      <div class="mb-4">
        <p class="text-gray text-xs mb-1">付款方式</p>
        <ElSelect
          v-model="formData.payName"
          :disabled="payOrders.length > 1"
          placeholder="请选择付款方式"
        >
          <ElOption value="balance" :label="`余额支付 (当前余额: ${formatAmount(userBalacne)})`"></ElOption>
          <ElOption value="USDT-TRC20" label="USDT-TRC20"></ElOption>
          <ElOption value="USDT-TON" label="USDT-TON"></ElOption>
        </ElSelect>
      </div>
      <div class="mb-4">
        <p class="text-gray text-xs mb-1">支付金额</p>
        <p class="text-2xl text-orange-400">{{ formatAmount(payOrdersTotal) }}</p>
      </div>
      <p class="text-gray text-xs mb-4"> 支付过程全程使用 TLS 安全通道，保证您的支付过程无安全隐患。 </p>
      <!-- <TonPayment @closePayment="closePayment" /> -->
      <van-button
        type="success"
        block
        :disabled="payLoading"
        :loading="payLoading"
        loading-text="支付中..."
        @click="submit"
      >
        确认支付
      </van-button>
    </div>
  </van-action-sheet>
  <PaymentConfirm ref="paymentConfirmRef" />
</template>

<script>
import { defineComponent, ref, reactive, computed } from 'vue'
import { fetchOrdersByOrderNosAction } from '@/api/order'
import { payAction } from '@/api/payment'
import { OrderFormat } from '@/utils/format'
import { useUserStore } from '@/store/user'
import { ElMessage } from 'element-plus'
import PayOrderList from './PayOrderList.vue'
import PaymentConfirm from './PaymentConfirm.vue'
// import TonPayment from './TonPayment.vue'

export default defineComponent({
  name: 'PaymentAction',
  components: {
    // TonPayment,
    PayOrderList,
    PaymentConfirm
  },
  setup() {
    const userStore = useUserStore()
    const userBalacne = computed(() => userStore.user.balance || 0)
    const paymentConfirmRef = ref()
    const show = ref(false)
    const activeCollapse = ref([])
    const formData = reactive({
      payWay: 'balance', // 支付方式
      payName: 'balance' // 支付名称
    })
    let orderNos = ''
    const product = ref({})
    const payOrders = ref([])
    const payOrdersSubtotal = ref(0) // 当页金额小计
    const payOrdersTotal = ref(0) // 全部订单总计
    const commonOrderInfo = ref({}) // 通用订单信息

    let isReSign = false // 是否是重签订单
    let staticPayOrders = []
    const total = ref(0)
    const pagination = reactive({
      limit: 10,
      offset: 1
    })
    let watchPayCallback = null // 监听支付回调函数

    const closePayment = () => {
      show.value = false
    }

    // 支付成功
    const onPaySuccess = (data) => {
      show.value = false
      if (watchPayCallback) {
        watchPayCallback(data)
      }
    }

    // 对外暴露: 打开支付面板
    const open = (v, payCallback) => {
      // 批量订单只能走余额支付
      // if (/,/.test(v)) {
      //   formData.payName = 'balance'
      //   formData.payWay = 'balance'
      // } else {
      //   formData.payName = 'USDT-TRC20'
      //   formData.payWay = 'selfusdt'
      // }
      watchPayCallback = payCallback
      // 查询订单列表
      return fetchOrdersByOrderNosAction(v)
        .then(result => {
          if (result.code !== 200) return
          staticPayOrders = result.data.allOrders
          orderNos = staticPayOrders.map(item => item.orderNo).toString()
          total.value = staticPayOrders.length
          payOrders.value = staticPayOrders.slice(0, pagination.limit)
          payOrdersSubtotal.value = staticPayOrders.reduce((p, c) => {
            if (c.reSignStatus === '5004') {
              return p + c.reSignPriceDiff
            }
            return p + c.payAmount
          }, 0)
          payOrdersTotal.value = payOrdersSubtotal.value

          // 判别重签
          isReSign = staticPayOrders.find((item) => item.reSignStatus === '5004')
          console.log('isReSign', isReSign)

          if (staticPayOrders.length > 0) {
            const [firOrder] = staticPayOrders
            const { productName, productNo, brandName, certType } = firOrder
            commonOrderInfo.value = firOrder
            product.value = { productName, productNo, brandName, certType }
          }
          show.value = true
          return result
        })
    }

    const payResult = {
      code: 'WAIT', // WAIT | SUCCESS | FAIL
      message: '',
      payParams: {},
      result: {}
    }
    const qrUrl = ref('')
    const usdtPayURL = ref('')
    const payLoading = ref(false)
    // 提交支付表单
    const submit = async () => {
      payLoading.value = true
      const params = {
        payWay: 'balance',
        aim: isReSign ? 'reSign' : 'makeOrder',
        orderNo: orderNos,
        name: formData.payName
      }
      if (/USDT/i.test(params.name)) {
        params.payWay = 'selfusdt'
      }
      payResult.payParams = params
      try {
        const result = await payAction(params)
        payResult.result = result
        if (result.code !== 200) {
          payResult.code = result.code
          payResult.message = 'fail'
          payLoading.value = false
          // watchPayCallback(payResult)
          ElMessage.warning(result.msg)
          return
        }
        payResult.code = 200
        payResult.message = 'ok'
        // 余额支付
        if (params.name === 'balance') {
          ElMessage.success('支付成功')
          onPaySuccess(payResult)
          return
        }
        const { qrCodeBase64, payURL } = result.data
        qrUrl.value = buildImageBaseURL(qrCodeBase64)
        if (/selfusdt|Nowpayment/i.test(params.payWay)) {
          usdtPayURL.value = payURL
          paymentConfirmRef.value.open({
            ...result.data,
            usdtPayURL: payURL,
            payResult
          }, onPaySuccess)
        }
        // 支付二维码
        if (qrUrl.value) {
          // checkoutSecond = 0;
          // clearInterval(checkoutTimer);
          // checkoutOrderStatus();
        }
      } catch (err) {
        console.log('err', err)
      }
      payLoading.value = false
    }

    const onActionSheetCancel = () => {
    }
    return {
      show,
      open,
      submit,
      payLoading,
      payOrders,
      formData,
      closePayment,
      userBalacne,
      payOrdersTotal,
      activeCollapse,
      paymentConfirmRef,
      onActionSheetCancel,
      formatProductName: OrderFormat.formatProductName
    }
  }
})

// 完善base64 url
function buildImageBaseURL(base64 = '') {
  return base64 ? `data:image/jpeg;base64,${base64}` : base64
}
</script>

<style lang="less" scoped></style>
