<template>
  <van-action-sheet
    v-model:show="show"
    title="支付中"
    @cancel="onActionSheetCancel"
  >
    <div class="p-4 text-sm">
      <p class="text-gray text-xs mb-1">点击地址直接复制</p>
      <div
        class="card p-2 mb-2 flex items-center justify-between text-wrap flex-wrap break-words"
        @click="handleClipboard(usdtPayURL, $event)"
      >
        <!-- <span class="text-wrap inline-block">{{ usdtPayURL }}</span> -->
        {{ usdtPayURL }}
        <!-- <van-icon
          name="/icon/copy.svg"
          color="#ffffff"
          size="22px"
        /> -->
      </div>
      <p class="text-gray text-xs mb-4"> 支付过程全程使用 TLS 安全通道，保证您的支付过程无安全隐患。 </p>
      <van-button
        type="success"
        block
        :disabled="confirmLoading"
        :loading="confirmLoading"
        loading-text="支付结果查询中..."
        @click="confirm"
      >
        我已支付
      </van-button>
    </div>
  </van-action-sheet>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { handleClipboard } from '@/utils/clipboard'
import { fetchOrderDetails } from '@/api/order'

export default defineComponent({
  name: 'PaymentConfirm',
  setup() {
    const show = ref(false)
    const usdtPayURL = ref('123123123')
    const confirmLoading = ref(false)
    let watchPayCallback = null
    let payParams = {}
    let checkoutTimer = 0
    let checkoutSecond = 0
    let checkOrderNo = ''

    // 停止检查支付状态
    const stopCheck = () => {
      clearInterval(checkoutTimer)
      checkoutTimer = 0
      show.value = false
    }

    // 轮询查状态
    let isChecking = false
    const checkPayStatus = async () => {
      clearInterval(checkoutTimer)
      checkoutTimer = setInterval(() => {
        // 默认60s 后过期
        if (checkoutSecond >= 60) {
          checkoutSecond = 0
          clearInterval(checkoutTimer)
        }
        // 非请求状态下不检查
        if (isChecking && checkoutSecond > 0) return
        checkoutSecond++
        if (payParams.aim === 'recharge') {
          // 充值
        } else {
          // 查询第一个订单支付状态
          fetchOrderDetails(checkOrderNo).then(({ data: result }) => {
            isChecking = false
            if (result.code !== 200) return
            if (result.data.orderInfo.orderStatus !== '1001') {
              ElMessage.success('支付成功')
              watchPayCallback && watchPayCallback()
              stopCheck()
            }
          })
        }
      })
    }

    const confirm = async () => {
      confirmLoading.value = true
      // 查询订单信息
      if (payParams.aim === 'recharge') {
        // 充值
        watchPayCallback && watchPayCallback()
      } else {
        const result = await fetchOrderDetails(checkOrderNo)
        if (result.code !== 200) {
          ElMessage.warning('未查询到支付结果')
          return
        }
        if (result.data.orderInfo.orderStatus !== '1001') {
          ElMessage.success('支付成功')
          watchPayCallback && watchPayCallback()
          stopCheck()
        }
      }
      confirmLoading.value = false
    }

    const open = (data, payCallback) => {
      show.value = true
      payParams = data.payResult.payParams
      // 截取需要监听的订单号
      if (/,/.test(payParams.orderNo)) {
        checkOrderNo = payParams.orderNo.split(',')[0]
      } else {
        checkOrderNo = payParams.orderNo
      }
      watchPayCallback = () => payCallback(data.payResult)
      usdtPayURL.value = data.usdtPayURL
      checkPayStatus()
    }

    const onActionSheetCancel = () => {}
    return {
      show,
      open,
      confirm,
      usdtPayURL,
      confirmLoading,
      handleClipboard,
      onActionSheetCancel
    }
  }
})
</script>

<style lang="less" scoped>
.card {
  border-radius: var(--van-radius-sm);
  overflow: hidden;
  background-color: var(--van-background-2);
}
</style>
