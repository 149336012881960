import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      activeMenu: 'home'
    }
  },
  {
    path: '/order/:orderNo',
    name: 'order-details',
    component: () => import('@/views/home/details/index.vue')
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: () => import('@/views/certificates/index.vue'),
    meta: {
      activeMenu: 'certificates'
    }
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/mine/index.vue'),
    redirect: '/mine/contact', // 默认跳转到 contact 页面
    children: [
      {
        path: 'contact',
        name: 'mine-contact',
        component: () => import('@/views/mine/contact/index.vue'),
        meta: {
          activeMenu: 'mine'
        }
      },
      {
        path: 'organization',
        name: 'mine-organization',
        component: () => import('@/views/mine/organization/index.vue'),
        meta: {
          activeMenu: 'mine'
        }
      },
      {
        path: 'transaction',
        name: 'mine-transaction',
        component: () => import('@/views/mine/transaction/index.vue'),
        meta: {
          activeMenu: 'mine'
        }
      }
    ]
  },
  {
    path: '/mine/contact/edit/:id',
    name: 'mine-contact-edit',
    component: () => import('@/views/mine/contact/edit.vue')
  },
  {
    path: '/mine/organization/edit/:id',
    name: 'mine-organization-edit',
    component: () => import('@/views/mine/organization/edit.vue')
  },
  {
    path: '/setting',
    name: 'settingt',
    component: () => import('@/views/setting/index.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
