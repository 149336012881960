import GSelect from './GSelect/index.vue'
import CountrySelect from './CountrySelect/index.vue'
import PaymentAction from './PaymentAction/index.vue'
import DomainTexarea from './DomainTexarea/index.vue'
// https://vue-select.org/guide/install.html#yarn-npm
// import VSelect from 'vue-select'
import { ElSelect, ElOption, ElOptionGroup, ElSwitch, ElButton, ElInput } from 'element-plus'
import 'element-plus/theme-chalk/base.css'
import 'element-plus/theme-chalk/el-input.css'
import 'element-plus/theme-chalk/el-button.css'
import 'element-plus/theme-chalk/el-select.css'
import 'element-plus/theme-chalk/el-switch.css'
import 'element-plus/theme-chalk/el-notification.css'
import 'element-plus/theme-chalk/el-loading.css'
import 'element-plus/theme-chalk/el-message.css'
import 'element-plus/theme-chalk/el-message-box.css'
export default function install(app) {
  app.component('GSelect', GSelect)
  app.component('CountrySelect', CountrySelect)
  app.component('PaymentAction', PaymentAction)
  app.component('DomainTexarea', DomainTexarea)
  // app.component('VSelect', VSelect)
  app.component('ElButton', ElButton)
  app.component('ElInput', ElInput)
  app.component('ElSelect', ElSelect)
  app.component('ElOption', ElOption)
  app.component('ElOptionGroup', ElOptionGroup)
  app.component('ElSwitch', ElSwitch)
}
