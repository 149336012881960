<template>
  <div>
    <van-field
      v-bind="$attrs"
      v-model="countryLabel"
      readonly
      @click="actionSheetShow = true"
    />
  </div>
  <van-popup
    v-model:show="actionSheetShow"
    round
    position="bottom"
    title="选择国家"
  >
    <van-picker
      v-model="country"
      :columns="countries"
      @cancel="showPicker = false"
      @confirm="onConfirm"
    />
  </van-popup>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Axios from 'axios'

export default defineComponent({
  name: 'CountrySelect',
  props: {
    code: {
      type: String,
      default: ''
    }
  },
  emits: ['update:code'],
  setup(props, { emit }) {
    const actionSheetShow = ref(false)
    const country = ref([])
    const countryLabel = ref('')
    const countries = ref([])

    if (!countries.value.length) {
      Axios.get('/resources/country.json')
        .then(res => {
          countries.value = res.data.data.map(item => {
            return {
              text: `${item.countryName} - ${item.countryCode}`,
              value: item.countryCode,
              ...item
            }
          })
          if (props.code) {
            country.value = [props.code]
            countryLabel.value = (countries.value.find(item => item.value === props.code) || {}).text
            return
          }
          country.value = [countries.value[0].value]
          countryLabel.value = countries.value[0].countryName
          emit('update:code', countries.value[0].value)
        })
    }

    const onConfirm = (val) => {
      countryLabel.value = val.selectedOptions[0].countryName
      actionSheetShow.value = false
      emit('update:code', val.selectedOptions[0].value)
    }
    return {
      country,
      countries,
      onConfirm,
      countryLabel,
      actionSheetShow
    }
  }
})
</script>

<style lang="less" scoped>
</style>
