import request from '@/utils/request'

export const payAction = (params = {}) => {
  // 超过20个订单走批量支付
  if (/,/.test(params.orderNo)) {
    const url = '/v1/orders/batchPay'
    return request.post(url, { orderNos: params.orderNo })
  }
  return request.post('/v1/orders/pay', params)
}
