/*
 * @Descripttion: your project
 * @version: 0.0.0
 * @Author: Minyoung
 * @Date: 2022-06-20 10:42:57
 * @LastEditors: Minyoung
 * @LastEditTime: 2022-07-10 16:58:01
 */
import Clipboard from 'clipboard'
import { ElMessage } from 'element-plus'

const clipboardLang = {
  已复制: '已复制',
  '复制失败！': '复制失败！'
}

function clipboardSuccess() {
  ElMessage({
    message: clipboardLang['已复制'],
    type: 'success',
    zIndex: 9999
  })
}

function clipboardError() {
  ElMessage({
    message: clipboardLang['复制失败'],
    type: 'error'
  })
}

export function setClipboardLang(content = {}) {
  Object.keys(clipboardLang).forEach(k => {
    clipboardLang[k] = content[k] || k
  })
}

export function handleClipboard(text, event) {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    clipboardSuccess()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError()
    clipboard.destroy()
  })

  clipboard.onClick(event)
}

export default handleClipboard
