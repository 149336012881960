/* eslint-disable */
import * as asn1js from 'asn1js';
import * as pkijs from 'pkijs'
import md5 from 'md5'
import { arrayBufferToString, stringToArrayBuffer, fromBase64, toBase64 } from 'pvutils';

const EnvelopedData = pkijs.EnvelopedData
const ContentInfo = pkijs.ContentInfo

let encryptionVariant = 2;
const encryptionAlgorithm = {
	name: 'AES-CBC',
	length: 256
};

let preDefinedDataBuffer = new ArrayBuffer(0)
let valueBuffer = new ArrayBuffer(0)
let cmsEnvelopedBuffer = new ArrayBuffer(0)

// 格式化 PEM
function formatPEM(pemString) {
	const PEM_STRING_LENGTH = pemString.length, LINE_LENGTH = 64;
	const wrapNeeded = PEM_STRING_LENGTH > LINE_LENGTH;

	if(wrapNeeded)
	{
		let formattedString = '', wrapIndex = 0;

		for(let i = LINE_LENGTH; i < PEM_STRING_LENGTH; i += LINE_LENGTH)
		{
			formattedString += pemString.substring(wrapIndex, i) + '\r\n';
			wrapIndex = i;
		}

		formattedString += pemString.substring(wrapIndex, PEM_STRING_LENGTH)
		return formattedString;
	}
	else
	{
		return pemString;
	}
}

// 添加加密数据并加密
function envelopedEncryptInternal(vb) {
	// 默认keylength 256
	if(encryptionVariant === 1) {
		if(preDefinedDataBuffer.byteLength > 32) {
			const newPreDefinedDataBuffer = new ArrayBuffer(32)
			const newPreDefinedDataView = new Uint8Array(newPreDefinedDataBuffer)

			const preDefinedDataView = new Uint8Array(preDefinedDataBuffer)

			for(let i = 0; i < 32; i++)
				newPreDefinedDataView[i] = preDefinedDataView[i];

			preDefinedDataBuffer = newPreDefinedDataBuffer;
		}

		if(preDefinedDataBuffer.byteLength < 32) {
			const newPreDefinedDataBuffer = new ArrayBuffer(32)
			const newPreDefinedDataView = new Uint8Array(newPreDefinedDataBuffer)

			const preDefinedDataView = new Uint8Array(preDefinedDataBuffer)

			for(let i = 0; i < preDefinedDataBuffer.byteLength; i++)
				newPreDefinedDataView[i] = preDefinedDataView[i];

			preDefinedDataBuffer = newPreDefinedDataBuffer;
		}
	}
	//endregion

	const cmsEnveloped = new EnvelopedData()

	cmsEnveloped.addRecipientByPreDefinedData(preDefinedDataBuffer, {}, encryptionVariant)

	// return cmsEnveloped.encrypt(encryptionAlgorithm, valueBuffer).then(() => {
	return cmsEnveloped.encrypt(encryptionAlgorithm, vb).then(() => {
    const cmsContentSimpl = new ContentInfo()
    cmsContentSimpl.contentType = '1.2.840.113549.1.7.3';
    cmsContentSimpl.content = cmsEnveloped.toSchema()

    cmsEnvelopedBuffer = cmsContentSimpl.toSchema().toBER(false)
    return cmsEnvelopedBuffer
  },
  error => Promise.reject(`ERROR DURING ENCRYPTION PROCESS: ${error}`)
  )
}

// 加密行为控制
export function envelopedEncrypt(password, content) {
  return Promise.resolve()
  .then(() => {
    // 自定义密码
    preDefinedDataBuffer = stringToArrayBuffer(password)
    // 加密的内容
		// valueBuffer = stringToArrayBuffer(content)
		return stringToArrayBuffer(content)
  })
  .then((cb) => envelopedEncryptInternal(cb))
  .then((ceb) => {
		// let resultString = '-----BEGIN CMS-----\r\n';
		let resultString = '-----BEGIN ENCRYPTED PRIVATE KEY------\r\n';
		// resultString = `${resultString}${formatPEM(toBase64(arrayBufferToString(cmsEnvelopedBuffer)))}`;
		resultString = `${resultString}${formatPEM(toBase64(arrayBufferToString(ceb)))}`;
		// resultString = `${resultString}\r\n-----END CMS-----\r\n`;
    resultString = `${resultString}\r\n-----END ENCRYPTED PRIVATE KEY------\r\n`;
    return resultString
	})
}

// 根据输入的密码解密
function envelopedDecryptInternal(preDefinedDataBuffer, cmsEnvelopedBuffer) {
	if(encryptionVariant === 1) {
		if(preDefinedDataBuffer.byteLength > 32)
		{
			const newPreDefinedDataBuffer = new ArrayBuffer(32)
			const newPreDefinedDataView = new Uint8Array(newPreDefinedDataBuffer)

			const preDefinedDataView = new Uint8Array(preDefinedDataBuffer)

			for(let i = 0; i < 32; i++)
				newPreDefinedDataView[i] = preDefinedDataView[i];

			preDefinedDataBuffer = newPreDefinedDataBuffer;
		}

		if(preDefinedDataBuffer.byteLength < 32)
		{
			const newPreDefinedDataBuffer = new ArrayBuffer(32)
			const newPreDefinedDataView = new Uint8Array(newPreDefinedDataBuffer)

			const preDefinedDataView = new Uint8Array(preDefinedDataBuffer)

			for(let i = 0; i < preDefinedDataBuffer.byteLength; i++)
				newPreDefinedDataView[i] = preDefinedDataView[i];

			preDefinedDataBuffer = newPreDefinedDataBuffer;
		}
	}
  const asn1 = asn1js.fromBER(cmsEnvelopedBuffer)
	const cmsContentSimpl = new ContentInfo({ schema: asn1.result })
	const cmsEnvelopedSimp = new EnvelopedData({ schema: cmsContentSimpl.content })

	return cmsEnvelopedSimp.decrypt(0, {
    preDefinedData: preDefinedDataBuffer
  })
}

// 解密行为控制
export function envelopedDecrypt(password, encryptContent) {
	return Promise.resolve().then(() => {
		const preDefinedDataBuffer = stringToArrayBuffer(password)
		const encodedCMSEnveloped = encryptContent
    // let clearEncodedCMSEnveloped = encodedCMSEnveloped.replace(/(-----(BEGIN|END)( NEW)? CMS-----|\n|[\s])/g, '')
    const clearEncodedCMSEnveloped = encodedCMSEnveloped.replace(/((BEGIN|END)( ENCRYPTED PRIVATE KEY)|(-)|\n|[\s])/g, '')
    const cmsEnvelopedBuffer = stringToArrayBuffer(fromBase64(clearEncodedCMSEnveloped))
		return { preDefinedDataBuffer, cmsEnvelopedBuffer }
	}).then(({ preDefinedDataBuffer, cmsEnvelopedBuffer }) => envelopedDecryptInternal(preDefinedDataBuffer, cmsEnvelopedBuffer)).then(result => {
    return arrayBufferToString(result)
	})
}

// hash uuid & spark
export function genSpark(value = '') {
	const splitIndex = []
	const chunks = value.split('-')
	chunks.forEach((item, index) => {
		if (index === 0) {
			splitIndex.push(item.length + 1)
		} else {
			if (index === chunks.length - 1) return
			splitIndex.push(splitIndex[index - 1] + item.length + 1)
		}
	})
	value = value.replace(/-/g, '_')
	value = value.split('').reverse().join('')
	const spark = value
	return { spark }
}

// spark to password
export function sparkToPass(value = '', splitIndex = []) {
	value = md5(value)
	const valueMd5Chunks = value.split('')
	const newValues = valueMd5Chunks.map((item, index) => {
		splitIndex.forEach(sIndex => {
			if (index === sIndex) {
				item += '-'
			}
		})
		return item
	})
	value = newValues.join('')
	return value
}