<template>
  <div>
    <div class="mb-4">
      <p class="text-gray text-xs mb-1">支付订单</p>
      <van-collapse v-model="activeCollapse" class="rounded-lg overflow-hidden">
        <van-collapse-item
          v-for="item in orders"
          :key="item.orderNo"
          :title="item.orderNo"
          :name="item.orderNo"
        >
          <div class="text-xs order-preview">
            <div class="flex justify-between text-gray mb-1">
              <span>产品</span>
              <span>{{ formatProductName(item) }}</span>
            </div>
            <div class="flex justify-between text-gray mb-1">
              <span>购买年限</span>
              <span>{{ item.validity }} 年</span>
            </div>
            <div class="flex justify-between text-gray mb-1">
              <span class="whitespace-nowrap block mr-4">通用名称</span>
              <span class="break-all">{{ item.commonName }}</span>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { OrderFormat } from '@/utils/format'

export default defineComponent({
  name: 'PayOrderList',
  props: {
    orders: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const activeCollapse = ref([])

    return {
      activeCollapse,
      formatProductName: OrderFormat.formatProductName
    }
  }
})
</script>

<style lang="less" scoped>
</style>
